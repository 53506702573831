import { Button, Menu, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import '../../assets/styles/app.less';
import OrdersActions from '../../redux/actions/orders';
import RoutesActions from '../../redux/actions/routes';
import Spacer from '../common/Spacer';
import {
  Divider,
  LabelName,
  LogoContainer,
  LogoHeader,
  // LogoText,
  MenuItem,
  MenuItemGroup,
  SiderContainer,
} from './components';
import PimItems, { IMenuItem } from './pimItem';
import CommerceCentralItems from './commerceCentralItem';
import PIMSVG from '../../assets/icons/pim';
import WMSSVG from '../../assets/icons/wms';
import ERPSVG from '../../assets/icons/erp';
// import linkERP from '../../util/linkERP';
import { fetchSiteConfig } from '../../util';

const { SubMenu } = Menu;

const hasPermissions = (permissions: any, menu: any) => (permissions.has(menu.permissionNum) && permissions.get(menu.permissionNum).PermissionLevel > 0) || menu.permissionNum === undefined;

/**
 * Custom hook that builds the side menu.
 * @param permissions
 */
function useMenuItems(permissions: any, its: any[]) {
  // Build initial items if none
  const newItems: Array<IMenuItem> = [];

  its.forEach((item) => {
    const newItem = { ...item };
    if (!item.children && !hasPermissions(permissions, item)) {
      return;
    }

    if (item.children) {
      newItem.children = item.children.filter((child: any) => hasPermissions(permissions, child));
    }

    newItems.push(newItem);
  });

  return newItems

}

interface RenderMenuProps {
  item: IMenuItem;
  collapsed: boolean;
  onClick?: Function;
}

/**
 * Render function for recursive menu rendering support.
 * @param props
 */
function renderMenu({ item, collapsed, onClick }: RenderMenuProps) {
  if (item.visible === false) {
    return null;
  }

  const menuProps: any = {
    key: item.key,
  };

  if (item.onClick) {
    menuProps.onClick = item.onClick;
  }

  if (Object.keys(item).length === 1) {
    if (item.key.includes('spacer')) {
      return <Spacer key={item.key} height={60} />;
    } if (item.key.includes('divider')) {
      return <Divider key={item.key} style={{ opacity: 0.5 }} />;
    }
  }

  if (item.children) {
    const children = item.children.map(
      (child: IMenuItem) => (renderMenu({ collapsed, onClick, item: child })),
    );

    return item?.group ? (
      <MenuItemGroup
        key={item.key}
        title={item.name}
      >
        {children}
      </MenuItemGroup>
    ) : (
      <SubMenu
        key={item.key}
        icon={item.icon}
        title={<LabelName>{item.name}</LabelName>}
      >
        {children}
      </SubMenu>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MenuItem icon={item.icon} {...menuProps}>
      {
        item.link && (
          <LabelName>
            {item.name}
            <a href={item.link} rel="noreferrer" target='_blank'>{null}</a>
          </LabelName>
        )
      }
      {
        !item.link && (
          <LabelName>
            {item.name}
            {item.route && <Link id={`main-menu-item-${item.key}`} to={item.route} onClick={() => onClick && onClick(item.key)} />}
          </LabelName>
        )
      }
    </MenuItem>
  );
}

function Sidebar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const permissions = useSelector((state: any) => state.profiles.permissions);
  // const profileEntity = useSelector((state: any) => state.profiles);
  const collapse = useSelector((state: any) => state.sidebar.collapse);
  const routeActions = bindActionCreators(RoutesActions, dispatch);
  const ordersActions = bindActionCreators(OrdersActions, dispatch);
  const [pimMenu, setPimMenu] = useState(useMenuItems(permissions, PimItems));
  const [commerceCentralMenu, setCommerceCentralMenu] = useState(useMenuItems(permissions, CommerceCentralItems));
  const [inited, setInited] = useState(false);
  const [pimType, setPimType] = useState(false);
  const [siteConfig, setSiteConfig] = useState<StringKAnyVPair>({});
  // const isAdmin = profileEntity?.permissions.has(Permissions.USER_PERMISSION) && profileEntity?.permissions.get(Permissions.USER_PERMISSION).PermissionLevel === 4;
  // const username = profileEntity?.permissions.values().next().value?.UserName;
  const currentKey = useSelector((state: any) => state.routes.currentKey);
  const currentOpenedKey = useSelector((state: any) => state.routes.currentOpenedKey);
  const profileSettings = useSelector((state: any) => state.profileSettings.profileSettings);

  const logoTextStyle = collapse ? { display: 'none' } : { paddingLeft: 10, paddingTop: 2, minWidth: 100 };

  // eslint-disable-next-line
  const filterPimMenu = (pms: IMenuItem[]) => {
    const items = [...pms];
    const disabledKeys = ['pimSettings', 'pim-control-tower'];
    const disabledSubKeys = ['controlTower'];
    const enableCT = getProfileSettingValue('EnableControlTower', '0') === '1';
    const enableAsDropShipper = getProfileSettingValue('EnableAsDropShipper', '0') === '1';
    const enableAsDropShippingSupplier = getProfileSettingValue('EnableAsDropShippingSupplier', '0') === '1';
    const enableLegacyExperience = getProfileSettingValue('LegacyExperience', '0') === '1';
    //console.log('en ----->', enableCT, profileSettings);

    for (let i = items.length - 1; i >= 0; i--) {
      const e = items[i];
      if (!enableCT) {
        if (disabledKeys.indexOf(e.key) > -1) {
          if (e.key === 'pimSettings') {
            if (Array.isArray(e.children)) {
              for (let j = e.children.length - 1; j >= 0; j--) {
                if (disabledSubKeys.indexOf(e.children[j].key) > -1) {
                  e.children.splice(j, 1);
                  //console.log('rm set');
                }
              }
            }
          } else {
            items.splice(i, 1);
            //console.log('rm menu');
          }
        }
      }

      if (e.key === 'pimDropshipping') {
        if (!enableAsDropShipper && !enableAsDropShippingSupplier) {
          items.splice(i, 1);
        }
        if (Array.isArray(e.children) && !enableAsDropShipper) {
          const dropShippingSupplierIdx = e.children.findIndex(item => item.key === 'pimDropshipping-from-dropshipping-supplier');
          e.children.splice(dropShippingSupplierIdx, 1);
        }
        if (Array.isArray(e.children) && !enableAsDropShippingSupplier) {
          const dropShipperIdx = e.children.findIndex(item => item.key === 'pimDropshipping-to-dropshipper');
          e.children.splice(dropShipperIdx, 1);
        }
      }

      if (e.key === 'pimSettings') {
        if (Array.isArray(e.children) && enableLegacyExperience) {
          const enssentialInfoIdx = e.children.findIndex(item => item.key === 'enssentialInfo');
          e.children.splice(enssentialInfoIdx, 1);
        }
      }

      if (e.key === 'pim-product-optimization' && process.env.NODE_ENV === 'production') {
        items.splice(i, 1);
      }

      if (e.key === 'pimSettings-enssentialInfo-media' && process.env.NODE_ENV === 'production') {
        items.splice(i, 1);
      }
      if (e.key === 'pimDropshipping' && process.env.NODE_ENV === 'production') {
        items.splice(i, 1);
      }
    }

    //return items;
    setPimMenu(items);
  };

  // eslint-disable-next-line
  const filterCommerCenterMenu = (pms: IMenuItem[]) => {
    const items = [...pms];

    for (let i = items.length - 1; i >= 0; i--) {
      const e = items[i];

      if (e.key === 'sell') {
        if (Array.isArray(e.children) && process.env.NODE_ENV === 'production') {
          const productOptimizationIdx = e.children.findIndex(item => item.key === 'sell-product-optimization-center');
          e.children.splice(productOptimizationIdx, 1);
        }
      }
      
    }

    //return items;
    setCommerceCentralMenu(items);
  };

  // const getControlTowerSetting = () => {
  //   let ret = false;

  //   const ps = profileSettings;

  //   if (Array.isArray(ps)) {
  //     const fs = ps.filter(e => e.SettingCode === 'EnableControlTower');

  //     if (fs.length === 1) {
  //       //console.log('sp', ps, fs);
  //       ret = fs[0].SettingValue === '1';
  //     }
  //   }

  //   return ret;
  // };

  const getProfileSettingValue = (key: string, defaultValue = '') => {
    const ps = profileSettings;

    if (Array.isArray(ps)) {
      const fs = ps.filter(e => e.SettingCode === key);

      if (fs.length === 1) {
        return fs[0].SettingValue;
      }
    }

    return defaultValue;
  }

  // eslint-disable-next-line
  const getSiteInfo = async () => {
    //const cfg = await fetchSiteConfig();
    const cfg = await fetchSiteConfig();

    setSiteConfig(cfg);
  };

  const handleOnOpenChange = (openKeys: Array<React.Key>) => {
    routeActions.setCurrentOpenedKey(openKeys);
  };

  const handleClick = (key: Array<React.Key>) => {
    routeActions.setCurrentKey(key);
    ordersActions.clearAllFilter();
  };

  const PlatFormButton = ({ color = '#d9d9d9', children = '', ...other }) => {
    return (
      <Button type="link" style={{ display: 'flex', alignItems: 'end', padding: 0 }} {...other}>
        {children === 'PIM' && <PIMSVG stroke={color} />}
        {children === 'ERP' && <ERPSVG stroke={color} />}
        {children === 'WMS' && <WMSSVG stroke={color} />}
        <Typography.Text style={{ color, fontSize: 13, marginLeft: 2, lineHeight: 1.5715, fontWeight: 600 }}>{children}</Typography.Text>
      </Button>
    )
  }

  useEffect(() => {
    const { pathname = '' } = location;
    if (location.pathname.includes('/pim')) {
      setPimType(true)
    }
    const key = pathname === '/' ? 'dashboard' : pathname.substr(1).replace('/', '-');
    const openedKey = key.split('-');
    routeActions.setCurrentKey([key]);
    routeActions.setCurrentOpenedKey(openedKey);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (!inited) {
      filterPimMenu(pimMenu);
      filterCommerCenterMenu(commerceCentralMenu)
      getSiteInfo();
      setInited(true);
    }
  }, [filterPimMenu,filterCommerCenterMenu, getSiteInfo, inited, pimMenu, commerceCentralMenu]);

  return (
    <SiderContainer className="custom-scroll-bar-menu" width={250} trigger={null} collapsible collapsed={collapse}>
      <LogoContainer>
        <LogoHeader>
          <Link to="/" onClick={() => { handleClick(['dashboard']); setPimType(false) }}>
            <img
              alt="logo"
              src={siteConfig.logo}
              width={pimType || collapse ? 32 : 34}
              height={pimType || collapse ? 32 : 34}
              // width = '40'
              // height = '40'
              style={{ borderRadius: 17, transition: 'all 0.5s' }}
            />
          </Link>
          {/* <LogoText style={logoTextStyle}>Digit Bridge</LogoText> */}
          <div style={logoTextStyle}>
            {pimType ? (
              <div>
                <Typography.Text style={{ fontSize: 16, fontWeight: 600, color: '#F9F5E7', letterSpacing: '0.3px', fontFamily: 'Lato' }}>
                  {siteConfig.name || ' '}
                </Typography.Text>
              </div>
            ) : (
              <>
                <div style={{ lineHeight: 1.2 }}>
                  <Typography.Text style={{ fontSize: 16, fontWeight: 600, color: '#F9F5E7', letterSpacing: '0.3px', fontFamily: 'Lato' }}>
                    {siteConfig.name || ' '}
                  </Typography.Text>
                </div>
                <div>
                  <Typography.Text style={{ fontSize: 12, fontWeight: 900, color: '#E89A26', letterSpacing: '0.2px', fontFamily: 'Lato' }}>
                    Commerce Central
                  </Typography.Text>
                </div>
              </>
            )}
          </div>
        </LogoHeader>
        <div
          style={
            collapse
              ? { opacity: 0, height: 0, display: 'none' }
              : {
                // padding: '2px 20px 0px 20px',
                minWidth: 200,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }
          }
        >
          <Link to={'/pim/dashboard'}>
            <PlatFormButton
              color={pimType ? '#FBB040' : '#d9d9d9'}
              onClick={() => {
                handleClick(['pim']);
                setPimType(true);
              }}
            >
              PIM
            </PlatFormButton>
          </Link>
          <PlatFormButton href={process.env.ERP_LINK}>ERP</PlatFormButton>
          {process.env.WMS_LINK && <PlatFormButton href={process.env.WMS_LINK}>WMS</PlatFormButton>}
          {!process.env.WMS_LINK && <span style={{width: 54}}>&nbsp;</span>}
        </div>
      </LogoContainer>
      {/* <Divider /> */}

      {pimType && (
        <Menu
          theme="dark"
          mode="inline"
          onOpenChange={handleOnOpenChange}
          onClick={({ keyPath }) => {
            console.log('keyPath = ', keyPath.reverse()/*, currentOpenedKey*/);
            // Skip storing the current key for logout action
            if (keyPath.indexOf('logout') >= 0) {
              return;
            }
            routeActions.setCurrentKey(keyPath.reverse());
          }}
          selectedKeys={currentKey}
          openKeys={currentOpenedKey}
        >
          {pimMenu.map((m) => renderMenu({ item: m, collapsed: collapse, onClick: handleClick }))}
        </Menu>
      )}
      {!pimType && (
        <Menu
          theme="dark"
          mode="inline"
          onOpenChange={handleOnOpenChange}
          onClick={({ keyPath }) => {
            console.log('keyPath = ', keyPath.reverse());
            // Skip storing the current key for logout action
            if (keyPath.indexOf('logout') >= 0) {
              return;
            }
            routeActions.setCurrentKey(keyPath.reverse());
          }}
          selectedKeys={currentKey}
          openKeys={currentOpenedKey}
        >
          {commerceCentralMenu.map((m) =>
            renderMenu({ item: m, collapsed: collapse, onClick: handleClick }),
          )}
        </Menu>
      )}
    </SiderContainer>
  );
}

export default Sidebar;
