import React, { useCallback } from "react";
import { Button, Card, Empty, message, Row, Space, Switch, Tag, Typography, } from "antd";
import CategorySelector from "../../../../components/common/SelectCategory";
import { createChannelTemplateByCategory } from "./../../../../services/ChannelIntegration";

type CategoryItem = {
  categoryId: number;
  categoryName: string;
  categoryParentId: number;
  level: number;
  isLeaf: boolean;
  categoryPath: string;
  treeStr: string;
};

type Props = {
  channelAccountNum: string;
  channelNum: string;
};

const ChannelCategory = (props: Props) => {
  const { useState } = React;
  const [categorySelectorVisible, setCategoryVisible] = useState(false);
  const [isMerge, setIsMerge] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<CategoryItem[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const closeCategorySelector = () => {
    setCategoryVisible(false);
  };

  const openCategorySelector = () => {
    setCategoryVisible(true);
  };

  const generateTemplate = useCallback(async () => {
    try {
      setLoading(true);
      const res = await createChannelTemplateByCategory(
        props.channelAccountNum,
        selectedCategories.map((i) => i.categoryId),
        isMerge,
        props.channelNum,
      );
      setLoading(false);
      if (res) {
        message.success("Generate template successfully");
        setSelectedCategories([]);
        setIsMerge(false)
      }
    } catch (error) {}
  }, [selectedCategories, props, isMerge]);

  return (
    <>
      <Card>
        <Button onClick={openCategorySelector}>Select Category</Button>
        <Card type="inner" style={{ marginTop: 8, width: 900, minHeight: 100 }}>
          {selectedCategories.length === 0 && (
            <Empty
              description={
                <Typography.Text type="secondary">
                  No categories selected
                </Typography.Text>
              }
            />
          )}
          {selectedCategories.map((i) => (
            <Row key={i.categoryId}>
            <Tag
              style={{ marginBottom: 8, fontSize: 14 }}
              closable
              onClose={() => {
                setSelectedCategories((prev) => {
                  const temp = prev.filter(
                    (item) => item.categoryId !== i.categoryId
                  );
                  return [...temp];
                });
              }}
            >
              {i.treeStr}
            </Tag>
            </Row>

          ))}
        </Card>
        <Row justify="space-between" style={{ marginTop: 8, width: 900 }}>
          <Space><Typography.Text>Combine all categories in one</Typography.Text><Switch checked={isMerge} onChange={(e)=> setIsMerge(e)}/></Space>
          <Button
            type="primary"
            loading={loading}
            onClick={() => generateTemplate()}
            disabled={selectedCategories.length === 0}
          >
            Generate Template
          </Button>
        </Row>
      </Card>
      {categorySelectorVisible && (
        <CategorySelector
          visible
          onHide={closeCategorySelector}
          defaultselected={selectedCategories.map((i) => i.categoryId)}
          onRefresh={(data, tree) => {
            if (data) {
              if (
                selectedCategories.findIndex(
                  (i) => i.categoryId === data.categoryId
                ) === -1
              ) {
                const treeStr = tree.map(i=> i.categoryName).toString().replaceAll(',',' -> ')
                setSelectedCategories((prev) => {
                  return [...prev, {...data, treeStr}];
                });
              }
            }
            closeCategorySelector();
          }}
        />
      )}
    </>
  );
};

export default ChannelCategory;
