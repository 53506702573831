import React, { useCallback, useEffect, useState } from "react";
import { Row, Button, Space, Typography, List, Breadcrumb } from "antd";
import {
  CloseOutlined,
  RightOutlined,
  StarOutlined,
  StarFilled,
} from "@ant-design/icons";
import ModalDialog from "../../../components/common/ModalDialog";
import { getCategories } from "../../../services/ChannelIntegration";
import styled from "styled-components";
import { ListItemProps } from "antd/lib/list";

const TItem: React.FunctionComponent<ListItemProps> = styled(List.Item)`
  &.ant-list-item:hover {
    background-color: #ebedf7;
  }
`;

type CategoryItem = {
  categoryId: number;
  categoryName: string;
  categoryParentId: number;
  level: number;
  isLeaf: boolean;
  categoryPath: string;
};
interface SelectCategoryProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: (selected: CategoryItem, tree: CategoryItem[]) => void;
  defaultselected: number[];
}

const SelectCategoryComponent = (props: SelectCategoryProps) => {
  const { visible, onHide, onRefresh, defaultselected } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<CategoryItem[]>([]);
  const [selected, setSelected] = useState<CategoryItem[]>([]);

  // const gridCtnRef = React.useRef<any>(null);

  const getCategoryData = useCallback(
    async (item: CategoryItem, index: number) => {
      try {
        setLoading(true);
        const res = await getCategories({
          categoryParentId: item.categoryId,
        });
        setLoading(false);
        if (res.isSuccess) {
          if (index === -2) {
            setSelected((prev) => {
              return [...prev, item];
            });
          } else if (index === -1) {
            setSelected([]);
          } else {
            setSelected((prev) => {
              const temp = [...prev];
              const s = temp.slice(0, index + 1);
              return [...s];
            });
          }
          setData(JSON.parse(res.data).categories);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    []
  );

  // const getDialogDom = () => {
  //   const gridCtn = gridCtnRef?.current;

  //   if (gridCtn) {
  //     return gridCtn.parentNode;
  //   }

  //   return null;
  // };

  // const onFullscreen = (full: boolean) => {
  //   const dialogBody = getDialogDom();

  //   if (!dialogBody) return;
  //   //console.log('full', full, dialogBody, dialogBody.parentNode);
  //   if (full) {
  //     // maybe there need to detect the window environment
  //     dialogBody.parentNode.parentNode.parentNode.style.maxWidth = `${
  //       window.innerWidth - 16
  //     }px`;
  //     setTimeout(() => {
  //       dialogBody.style.height = "auto";
  //       dialogBody.parentNode.style.height = "auto";
  //       //dialogBody.parentNode.parentNode.parentNode.style.maxWidth = `${window.innerWidth - 2}px`;
  //     }, 0);
  //     setTimeout(() => {
  //       dialogBody.parentNode.parentNode.parentNode.style.maxWidth = `${
  //         window.innerWidth - 2
  //       }px`;
  //     }, 200);
  //   }
  // };

  useEffect(() => {
    getCategoryData(
      {
        categoryId: 0,
        categoryName: "",
        categoryParentId: 0,
        level: 0,
        isLeaf: false,
        categoryPath: "",
      },
      0
    );
  }, [getCategoryData]);

  return (
    <ModalDialog
      title="Select Category"
      centered
      closable={false}
      // closeButton
      // fullscreen
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      //onCancel={() => onHide()}
      onClose={() => onHide()}
      // onFullscreen={onFullscreen}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button onClick={() => onHide()} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      {selected.length > 0 ? (
        <Row justify="space-between" align="middle">
          <Breadcrumb>
            {selected.map((i, index) => (
              <Breadcrumb.Item key={i.categoryId}>
                <Typography.Link
                  onClick={() => {
                    getCategoryData(i, index);
                  }}
                >
                  {i.categoryName}
                </Typography.Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <Button
            type="link"
            onClick={() => {
              getCategoryData(
                {
                  categoryId: 0,
                  categoryName: "",
                  categoryParentId: 0,
                  level: 0,
                  isLeaf: false,
                  categoryPath: "",
                },
                -1
              );
            }}
            icon={<CloseOutlined />}
          />
        </Row>
      ) : (
        <Row></Row>
      )}
      <List
        style={{
          height: 400,
          overflow: "auto",
          backgroundColor: "#fff",
        }}
        loading={loading}
        bordered
        dataSource={data}
        renderItem={(item: CategoryItem) => (
          <TItem
            onClick={() => {
              if (!item.isLeaf) {
                getCategoryData(item, -2);
              }
            }}
            actions={
              item.isLeaf
                ? [
                    <Button
                      size="small"
                      disabled={defaultselected.indexOf(item.categoryId) !== -1}
                      onClick={() => onRefresh(item, [...selected, item])}
                    >
                      Select
                    </Button>,
                  ]
                : [<RightOutlined />]
            }
          >
            <Typography.Text>
              {defaultselected.indexOf(item.categoryId) !== -1 ? (
                <StarFilled />
              ) : (
                <StarOutlined />
              )}
            </Typography.Text>{" "}
            {item.categoryName}
          </TItem>
        )}
      />
    </ModalDialog>
  );
};

export default SelectCategoryComponent;
