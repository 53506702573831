import { Button, Tabs } from 'antd';
import styled from 'styled-components';
import theme from '../../assets/styles/theme';

interface IButton {
  $hasPermission: boolean
}

export const CardTabs = styled(Tabs)`
  &.compact-tabs .ant-tabs-nav {
    margin-bottom: 8px;
  }

  & .ant-tabs-tab-active {
    background-color: #ECECEC !important;
  }

  & .ant-tabs-tab-active .ant-badge {
    color: ${theme['@primary-color']};
  }
`;

export const FormLabel = styled.span`
  font-weight: 550;

  &::after {
    content: ' :';
  }

  & .grey {
    color: #999;
  }

  & .italic {
    font-style: italic;
  }

  & .normal-weight {
    font-weight: 400;
  }

  & .prim {
    color: ${theme['@primary-color']};
  }

  & .sup-box {
    font-style: normal;
    padding: 5px 2px !important;
  }

  & .text-black {
    color: rgba(0, 0, 0, .85);
  }

  & .text-box {
    border: 1px solid;
  }

  & .text-size08 {
    font-size: 0.8em;
  }

  & .text-size09 {
    font-size: 0.9em;
  }

  & .text-tag {
    box-sizing: border-box;
    margin: 0;
    padding: 0 6px;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    /* line-height: 1.5715; */
    list-style: none;
    font-feature-settings: 'tnum';
    display: inline-block;
    height: auto;
    /* margin-right: 8px; */
    /* padding: 0 7px; */
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    opacity: 1;
    transition: all 0.3s;
  }
`;

export const DangerButton = styled(Button)`
  &.ant-btn {
    color: #fff;
    background-color: ${theme['@danger-color']};
    border-color: #bd2130;
  }

  &.ant-btn:disabled {
    background-color: inherit !important;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, .25);
  }

  &.ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
  }
`;

export const HeaderButton = styled(Button)`
  height: 40px;
  padding-top: 7px;
  margin-left: 5px;
  display: ${(props: IButton) => props.$hasPermission ? 'flex' : 'none'};
`;

type ColorTheme =
  | 'danger'
  | 'info'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning';

type HoverBgButtonProps = {
  hovercolor?: string;
  hovertype?: ColorTheme;
};

const hoverColor = (props: any) => {
  const { hovercolor, hovertype } = props;
  const colorDict = {
    danger: theme['@danger-color'],
    info: theme['@info-color'],
    primary: theme['@primary-color'],
    secondary: theme['@secondary-color'],
    success: theme['@success-color'],
    warning: theme['@warning-color'],
  };
  let color = 'none';

  if (hovertype in colorDict) color = colorDict[hovertype as ColorTheme];

  if (hovercolor) color = hovercolor;

  return 'string' === typeof color ? color : '';
};

export const HoverBgButton = styled(Button)<HoverBgButtonProps>`
  &.ant-btn:not([disabled]) {
    border-color: ${(props) => hoverColor(props)};
    color: ${(props) => hoverColor(props)};
  }

  &.ant-btn:not([disabled]):focus,
  &.ant-btn:not([disabled]):hover {
    background-color: ${(props) => hoverColor(props)};
    border-color: ${(props) => hoverColor(props)};
    color: #fff;
  }

  &.ant-btn:focus {
    background-color: inherit;
    color: inherit;
  }

  &.ant-btn:focus-within {
    background-color: ${(props) => hoverColor(props)};
    border-color: ${(props) => hoverColor(props)};
    color: #fff;
  }
`;

export const InfoButton = styled(Button)`
  &.ant-btn {
    color: #fff;
    background-color: ${theme['@info-color']};
    border-color: #117a8b;
  }

  &.ant-btn:active,
  &.ant-btn:focus {
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
  }

  &.ant-btn:hover {
    background-color: ${theme['@info-btn-hover-bg']};
  }

  &.ant-btn:disabled {
    /*background-color: inherit !important;*/
    background: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, .25);
  }

  &.ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
  }
`;

export const Label = styled.label`
  font-size: ${(props) => props.theme.fontSize || '1em'};
    
  &.clickable {
    cursor: pointer;
  }

  & span {
    font-size: ${(props) => props.theme.fontSize || '1em'};
  }

  &.label-bold,
  & .label-bold {
    font-weight: 600;
  }

  &.label-colon::after {
    content: ': ';
  }

  &.label-danger,
  & .label-danger {
    color: ${theme['@danger-color']};
  }

  &.label-grey,
  & .label-grey {
    color: #666;
  }

  &.label-info,
  & .label-info {
    color: ${theme['@info-color']};
  }

  &.label-italic,
  & .label-italic {
    font-style: italic;
  }

  &.label-primary,
  & .label-primary {
    color: ${theme['@primary-color']};
  }
`;

export const MaskLayer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
`;

export const ModalDialogBodyCtn = styled.div`
  height: 100%;
  position: relative;
`;

export const ModalTitle = styled.h3`
  font-weight: bold;
  margin-bottom: 0;
`;

export const ProductDetailDialogBodyWrapper = styled.div`
  /*max-height: calc(100vh - 180px);*/
  background-color: #FFF;
  border-radius: 4px;
  height: calc(100vh - 286px);
  overflow-y: auto;
  padding: 12px;

  &.fullscreen-mode {
    height: calc(100vh - 144px);
  }

  &.fullscreen-mode2 {
    height: calc(100vh - 92px);
  }
`;

export const SecondaryButton = styled(Button)`
  &.ant-btn {
    color: #fff;
    background-color: ${theme['@secondary-btn-bg']};
    border-color: ${theme['@secondary-btn-bg']};
  }

  &.ant-btn:active,
  &.ant-btn:focus {
    background-color: ${theme['@secondary-btn-active-bg']};
    border-color: ${theme['@secondary-btn-active-bg']};
  }

  &.ant-btn:hover {
    background-color: ${theme['@secondary-btn-hover-bg']};
    border-color: ${theme['@secondary-btn-hover-bg']};
  }

  &.ant-btn:disabled {
    background-color: inherit !important;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, .25);
  }

  &.ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
  }
`;

export const StyleInputWrapper = styled.div`
  & .ant-input:read-only {
    background-color: #DCDCDC;
  }

  & .ant-input-group-addon {
    border: solid 1px #D9D9D9 !important;
  }

  & .ant-input-group-addon:last-child {
    padding: 6px 8px 2px;
  }

  & .ant-input-group-wrapper {
    width: 580px;
  }

  &.img-field .ant-input-group-wrapper {
    width: 500px;
  }

  & .ant-input-affix-wrapper-readonly {
    background-color: #DCDCDC;
  }

  & .anticon-search {
    font-size: 18px;
  }

  & .code-selector-active .ant-input-group-addon:first-child {
    background-color: #E9F4FF !important;
  }

  @media screen and (max-width: 1199px) {
    & .ant-input-group-wrapper {
      max-width: 580px;
      width: calc(100vw - 300px);
    }
  }

  @media (min-width: 1200px) and (max-width: 1430px) {
    & .ant-input-group-wrapper {
      width: 500px;
    }
  }

  @media (min-width: 1550px) {
    &.img-field .ant-input-group-wrapper {
      width: 600px;
    }
  }
`;

export const messageStyle = {
  /*textAlign: 'right',
  marginRight: 8,*/
};
